import Request from '@/assets/js/requests.js'
import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'
import FonctionTransformer from '@/assets/js/dexie/transformers/FonctionTransformer.js'

var FonctionMixin = {
	data() {
		return {

		}
	},
	methods: {
		loadFonction: async function() {
			return this.$storage.db.t('fonction')
			.then(table => {
				return table.where({fonction_valide: 1})
			})
			.then(col => {
				return col.transform(new FonctionTransformer())
			})
		},

		loadFonctionByCode: async function(code) {
			return this.$storage.db.t('fonction')
			.then(table => {
				return table.where({
					fonction_code: code,
					fonction_valide: 1
				})
			})
			.then(col => {
				return col.transform(new FonctionTransformer())
			})
		},

		loadFonctionSecondaires: async function() {
			return this.$storage.db.t('fonction')
			.then(table => {
				return table.where({fonction_valide: 1}).and(fonction => fonction.fonction_code !== 'PROPRIO')
			})
			.then(col => {
				return col.transform(new FonctionTransformer())
			})
		},

		addFonction: function(params) {
			const fonction_id = Common.getNegativeId()

			this.$storage.db.t('fonction')
			.then(table => {
				return table.add({
					fonction_id: fonction_id,
					fonction_valide: 1,
					fonction_label: params.fonction_label,
					fonction_code: params.fonction_code
				})
			})

			return fonction_id
		},

		addTiersContactFonction: async function(fonction_id, tierscontact_id) {
			return this.$storage.db.t('tiers_contact_fonction')
			.then(table => {
				return table.add({
					tierscontactfonction_id: Common.getNegativeId(),
					tierscontactfonction_valide: 1,
					tierscontactfonction_fonction: parseInt(fonction_id),
					tierscontactfonction_tierscontact: parseInt(tierscontact_id)
				})
			})
		},

		deleteAllTiersContactFonction: async function(tierscontact_id) {
			return this.$storage.db.t('tiers_contact_fonction')
			.then(table => {
				return table
				.where('tierscontactfonction_tierscontact')
				.equals(parseInt(tierscontact_id))
				.invalid()
			})
		},

		addHorseContactFonction: async function(fonction_id, horsecontact_id) {
			const horsecontactfonction_id = Common.getNegativeId()
			return this.$storage.db.t('horse_contact_fonction')
			.then(table => {
				return table.add({
					horsecontactfonction_id: horsecontactfonction_id,
					horsecontactfonction_valide: 1,
					horsecontactfonction_fonction: parseInt(fonction_id),
					horsecontactfonction_horsecontact: parseInt(horsecontact_id)
				})
			})
			.then(col => {
				return horsecontactfonction_id
			})
		},

		deleteAllHorseContactFonction: async function(horsecontact_id) {
			return this.$storage.db.t('horse_contact_fonction')
			.then(table => {
				return table.filter(horsecontactfonction => (horsecontactfonction.horsecontactfonction_horsecontact == horsecontact_id)).invalid()
			})
		},
	}
}

export default FonctionMixin
