<template>
	<b-modal ref="modal" size="lg" hide-footer>
		<template v-if="tiers_id" v-slot:modal-title>
			<template v-if="ajouter">
				{{ $t("horse.ajouter_relation_tiers") }}
			</template>

			<template v-if="modifier">
				{{ $t("horse.modifier_relation_tiers") }}
			</template>
		</template>
		<template v-else v-slot:modal-title>
			<template v-if="ajouter">
				{{ $t("horse.ajouter_tiers_secondaire") }}
			</template>

			<template v-if="modifier">
				{{ $t("horse.modifier_tiers_secondaire") }}
			</template>
		</template>
		
		<div class="col-12" v-if="form_message !== ''">
			<ErrorAlert :messageI18n="form_message" />
		</div>

		<div class="form-group">
			<label for="tiers" class="col-form-label">{{ $t("tiers.tier") }} *</label>
			<e-select
				id="tiers_id"
				track-by="tiers_rs"
				label="tiers_rs"
				:placeholder="labelTitleTiers"
				:deselectLabel="deselectLabel"
				:selectedLabel="selectedLabel"
				:selectLabel="enter_to_select"
				:options="tiers_options"
				:searchable="true"
				:show-labels="false"
				v-model="tiersSelected"
				@select="onTierSelected"
			>
				<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>

		<div class="form-group">
			<label for="fonctions" class="col-form-label">{{ $t("horse.function") }} *</label>
			<e-select
				id="fonction_id"
				track-by="fonction_label"
				label="fonction_label"
				:placeholder="labelTitleFonctions"
				:deselectLabel="deselectLabel"
				:selectedLabel="selectedLabel"
				:selectLabel="enter_to_select"
				:options="fonctions_options"
				:searchable="true"
				:show-labels="false"
				v-model="fonctionSelected"
			>
				<template slot="singleLabel" slot-scope="{ option }">{{ option.fonction_label }}</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>

		<div class="form-group">
			<label for="contact" class="col-form-label">{{ $t("horse.contact") }}</label>
			<e-select
				id="contact_id"
				track-by="contact_label"
				label="contact_label"
				:placeholder="labelTitleContacts"
				:deselectLabel="deselectLabel"
				:selectedLabel="selectedLabel"
				:selectLabel="enter_to_select"
				:options="contact_formatted"
				:searchable="true"
				:show-labels="false"
				v-model="contactSelected"
			>
				<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_label }}</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>

		<b-button v-if="ajouter" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
		<b-button v-if="modifier" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button>
	</b-modal>
</template>

<script type="text/javascript">
	import ContactMixin from '@/mixins/Contact.js'
	import FonctionMixin from '@/mixins/Fonction.js'
	import Navigation from '@/mixins/Navigation.js'
	import TiersMixin from '@/mixins/Tiers.js'

	export default {
		name: "ModalTiersSecondaire",
		mixins: [ContactMixin, FonctionMixin, Navigation, TiersMixin],
		props: ['tiers_id'],
		data() {
			return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),

				labelTitleTiers: this.getTrad("tiers.search_tiers"),
				tiers_options: [],
				tiersSelected: null,

				labelTitleFonctions: this.getTrad("horse.search_function"),
				fonctions_options: [],
				fonctionSelected: null,

				labelTitleContacts: this.getTrad("horse.search_contact_tiers"),
				contacts_options: [],
				contactSelected: null,

				ajouter: false,
                modifier: false,
				processing: false,
				form_message: "",
				tiershorse_id: null,
				tiersrelations_id: null,
				params: null,
				horse_id: null
			}
		},
		methods: {
			async open(params = null, tiers_horse = null, tiers_relations = null) {
				this.params = params
				this.tiersSelected = null
				this.fonctionSelected = null
				this.contactSelected = null

				this.fonctions_options = await this.loadFonctionSecondaires()
				this.fonctions_options.forEach(fonction => {
					fonction.fonction_label = this.getTrad(fonction.fonction_label)
				})

				this.tiers_options = await this.loadTiers()

				if(tiers_horse){
					this.addOrEdit(tiers_horse, null)
				}
				else if(tiers_relations) {
					this.addOrEdit(null, tiers_relations)
				}
				else {
					this.addOrEdit()
				}
			},
			async addOrEdit(tiers_horse = null, tiers_relations = null) {
				this.processing = false
				if(tiers_horse) {
					this.ajouter = false
					this.modifier = true

					this.tiershorse_id = tiers_horse[0].tiershorse_id
					this.horse_id = tiers_horse[0].horse_id
					this.tiersSelected = tiers_horse[0].tiers
					this.contacts_options = await this.getContactByTiers(tiers_horse[0].tiers.tiers_id)
					this.fonctionSelected = tiers_horse[0].fonction
					this.fonctionSelected.fonction_label = this.getTrad(this.fonctionSelected.fonction_label)
					if(tiers_horse[0].tierscontact) {
						this.contactSelected = tiers_horse[0].tierscontact
						this.contactSelected.contact_label = tiers_horse[0].contact_firstname + ' ' + tiers_horse[0].contact_lastname + ' (' + tiers_horse[0].contact_phones_number + ')'
					}
				}
				else if(tiers_relations) {
					this.ajouter = false
					this.modifier = true

					this.tiersrelations_id = tiers_relations[0].tiersrelations_id
					this.tiersSelected = tiers_relations[0].tiersrelations_to
					this.contacts_options = await this.getContactByTiers(tiers_relations[0].tiersrelations_to.tiers_id)
					this.fonctionSelected = tiers_relations[0].fonction
					this.fonctionSelected.fonction_label = this.getTrad(this.fonctionSelected.fonction_label)
					if(tiers_relations[0].contact) {
						this.contactSelected = tiers_relations[0].contact
						this.contactSelected.contact_label = tiers_relations[0].contact_firstname + ' ' + tiers_relations[0].contact_lastname + ' (' + tiers_relations[0].contact_phones_number + ')'
					}
				}
				else {
					this.ajouter = true
					this.modifier = false
				}
                this.$refs.modal.show()
            },
			async onTierSelected(option) {
				this.contactSelected = null
				this.contacts_options = await this.getContactByTiers(option.tiers_id)
            },
			async checkForm() {
                if(!this.processing) {
					this.form_message = ""
					this.processing = true
                    if(this.tiersSelected && this.fonctionSelected) {
						if(this.ajouter && this.tiers_id) {
							this.addTiersRelations(this.tiers_id, this.tiersSelected.tiers_id, this.fonctionSelected.fonction_code, this.contactSelected ? this.contactSelected.contact_id : null)
						}
						else if(this.modifier && this.tiers_id) {
							this.editTiersRelations(this.tiersrelations_id, this.tiers_id, this.tiersSelected.tiers_id, this.fonctionSelected.fonction_code, this.contactSelected ? this.contactSelected.contact_id : null)
						}
						else if(this.ajouter) {
							this.params.forEach(param => {
								this.addTiersSecondaire(param.horse.horse_id, this.tiersSelected.tiers_id, this.fonctionSelected.fonction_code, this.contactSelected ? this.contactSelected.tierscontact_id : null)
							});
						}
						else if(this.modifier) {
							this.editTiersSecondaire(this.tiershorse_id, this.horse_id, this.tiersSelected.tiers_id, this.fonctionSelected.fonction_code, this.contactSelected ? this.contactSelected.tierscontact_id : null)
						}
						this.$refs.modal.hide()
						this.$emit('submit')
					}
					else {
						this.processing = false
						this.form_message = "error.TS"
					}
                }
            }
		},
		computed: {
			contact_formatted: function() {
				if(this.tiers_id) {
					return this.contacts_options.map(contact => {
						let contact_label = contact.contact.contact_firstname + ' ' + contact.contact.contact_lastname
						if(contact.contact.phones.length > 0) contact_label += ' (' + contact.contact.phones[0].phone_combine + ')'

						return {contact_id: contact.tierscontact_id, contact_label: contact_label}
					})
				}
				else {
					return this.contacts_options.map(tierscontact => {
						let contact_label = tierscontact.contact.contact_firstname + ' ' + tierscontact.contact.contact_lastname
						if(tierscontact.contact.phones.length > 0) contact_label += ' (' + tierscontact.contact.phones[0].phone_combine + ')'

						return {tierscontact_id: tierscontact.tierscontact_id, contact_label: contact_label}
					})
				}
			}
		},
        components: {
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
        }
	}
</script>